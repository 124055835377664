import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'

import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { Map, TileLayer } from 'react-leaflet-universal'
import 'leaflet/dist/leaflet.css'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

function Contact({ location }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          phone
          phoneDisplay
        }
      }
    }
  `)

  const { email, phone, phoneDisplay } = site.siteMetadata
  const [formStatus, setFormStatus] = useQueryParam('form', StringParam)

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setFormStatus('submitting')
      const form = event.target

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          name: form.elements.name.value,
          email: form.elements.email.value,
          phone: form.elements.phone.value,
          message: form.elements.message.value,
        }),
      })
        .then((res) =>
          setFormStatus(
            res.status >= 200 && res.status < 400 ? 'success' : 'error'
          )
        )
        .catch(() => setFormStatus('error'))
    },
    [setFormStatus]
  )

  return (
    <>
      <Meta
        title="Contact us"
        description="Contact information for ProGro Landscapes. Get in touch today to arrange a free quote."
        location={location}
      />
      <Layout accent>
        <section className="relative bg-white">
          <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div className="py-8 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Contact us today!
                </h2>
                <dl className="mt-8 text-base text-gray-light">
                  <div>
                    <dt className="sr-only">Postal address</dt>
                    <dd>
                      16 East Stratton Close,
                      <br />
                      Martins Heron,
                      <br />
                      Bracknell RG12 0XY
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-light"
                        aria-hidden="true"
                      />
                      <a
                        href={`tel:${phone}`}
                        className="ml-3 hover:text-green"
                      >
                        {phoneDisplay}
                      </a>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-light"
                        aria-hidden="true"
                      />
                      <a
                        href={`mailto:${email}`}
                        className="ml-3 hover:text-green"
                      >
                        {email}
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="bg-white py-8 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                {formStatus === 'success' ? (
                  <p className="my-16 mx-auto text-xl text-center">
                    Thank you, we&apos;ll be in touch soon!
                  </p>
                ) : (
                  <form
                    name="contact"
                    className="grid grid-cols-1 gap-y-6"
                    action="/contact/?form=success"
                    method="POST"
                    onSubmit={handleSubmit}
                    data-netlify
                  >
                    <label htmlFor="name">
                      <span className="sr-only">Full name</span>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        autoComplete="name"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green focus:border-green border-gray-light rounded-md"
                        placeholder="Full name"
                      />
                    </label>
                    <label htmlFor="email">
                      <span className="sr-only">Email</span>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="email"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green focus:border-green border-gray-light rounded-md"
                        placeholder="Email"
                      />
                    </label>
                    <label htmlFor="phone">
                      <span className="sr-only">Phone</span>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        autoComplete="tel"
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-green focus:border-green border-gray-light rounded-md"
                        placeholder="Phone"
                      />
                    </label>
                    <label htmlFor="message">
                      <span className="sr-only">Message</span>
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-light focus:ring-green focus:border-green border-gray-light rounded-md"
                        placeholder="Message"
                      />
                    </label>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green hover:bg-green-light disabled:bg-green-light disabled:cursor-default focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green"
                      disabled={formStatus === 'submitting'}
                    >
                      {formStatus === 'submitting' ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="max-w-7xl mx-auto lg:mb-16">
          <Map
            center={[51.40022, -0.72644]}
            zoom={12}
            scrollWheelZoom={false}
            className="h-96"
          >
            <TileLayer
              attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </Map>
        </section>
      </Layout>
    </>
  )
}

Contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}

export default Contact
